/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

:root {
  --ion-font-family: "rubik" !important;
  --ion-color-primary: #5688c7;
  --ion-color-secondary: #012851;
  --ion-color-success: #05c46b;
}

// Resets

.flex {
  display: flex;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;

  ion-item {
    width: 100%;
    margin: 0;
  }
}

.d-block {
  display: block;
}

.mt-0 {
  margin-top: 0;
}

.pt-0 {
  padding-top: 0;
}

// Card styles

ion-card {
  border: 1px solid transparentize(#5688c7, 0.9);
  box-shadow: 0px 4px 28px -10px transparentize(#5688c7, 0.8);
  // box-shadow: none;
}

.delete-alert {
  --min-width: 350px;
}

.w-100 {
  width: 100%;
}

.block {
  display: block !important;
}

// Toast styling

.app-toast {
  --background: #4caf50;
  font-weight: 500;
  font-size: 16px;
}

.ngx-datatable.material {
  border: 1px solid transparentize(#5688c7, 0.9) !important;
  box-shadow: 0px 4px 28px -10px transparentize(#5688c7, 0.8) !important;
}

.datatable-header-cell-label {
  font-weight: 800;
  text-transform: capitalize;
}


// Print 

@media print {
  body {
    position: relative !important;

  }

  @page {
    size: auto;
    margin-top: 0mm;
    margin-bottom: 0mm;
  }

  ion-header {
    display: none;
  }

  html,
  body {
    height: 100% !important;
    min-height: 100% !important;
    max-height: auto !important;
  }

  .wrapper {
    --overflow: hidden !important;
    --overflow-y: visible !important;
  }

  .ion-page {
    page-break-after: always;
    min-height: 2000px !important;
  }

  ion-content {
    height: 100% !important;
    position: relative !important;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  ion-footer {
    position: fixed;
    bottom: 0;
  }

  #printpagebutton {
    display: none;
  }
}



// 



ion-content {
  --background: url("https://wallpaperbat.com/img/418939-milk-wallpaper-food-hq-milk-picture-4k-wallpaper-2019.jpg") 0 0/100% 100% no-repeat;
}

ion-modal ion-content {
  --background: white !important;
}

ion-list-header {
  h1 {
    color: white;
  }
}

.select-alert {
  .alert-wrapper {
    // width: 100% !important;
    min-width: 400px !important;
  }
}

.datetime-calendar {
  min-width: 400px !important;
}

.popover-content {
  min-width: 400px !important;
}

.calendar-next-prev {
  position: absolute !important;
  right: 71px !important;
  width: 40px !important;
}

.datetime-size-fixed,
.popover-desktop {
  width: 300px !important;
  max-width: 300px !important;
  --max-width: 300px !important;
  --width: 300px !important;
}


// .visible-on-mobile {
//   display: none;
// }

// .hidden-on-mobile {
//   display: block;
// }

.visible-on-mobile-only {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}



.justify-center {
  justify-content: center;
}

.icon-size {
  font-size: 20px;
}

@media(max-width:768px) {
  .visible-on-mobile-only {
    display: block;
  }

  .hidden-on-mobile {
    display: none;
  }

  .mobile-no-margin {
    margin: 0;
  }

  .mobile-no-padding {
    padding: 0;
  }

  .mobile-block {
    display: block;
  }
}